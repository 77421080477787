import { Row, Col, Image } from 'react-bootstrap'
import logo from './logo.png'

export const Logo: React.FC<{}> = (props) => {
  return (
    <Row className="p-4">
      <Col>
        <Image src={logo} fluid={true} />
      </Col>
    </Row>
  )
}
