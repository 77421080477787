import { Container, Row, Col } from 'react-bootstrap'

export const Header: React.FC<{}> = (props) => {
  return (
    <Row className="justify-content-md-center header p-2">
      <Col sm>Honest Rx Pharmacy</Col>
      <Col sm>
        <a href="tel:540-490-4350">540-490-4350</a>
      </Col>
      <Col sm>Mon-Fri 9-6</Col>
      <Col sm>2225 N Augusta St. Staunton VA</Col>
      <Col sm>
        <a href="https://www.facebook.com/Honest-Rx-110400501643325">
          Facebook
        </a>
      </Col>
    </Row>
  )
}
