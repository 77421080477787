import { Header } from './Header'
import { Logo } from './Logo'
import { Why } from './Why'
import { About } from './About'
import { Search } from './Search'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'

function App() {
  return (
    <Container fluid>
      <Header />
      <Logo />
      <Search />
      <Why />
      <About />
    </Container>
  )
}

export default App
