import { Row, Col, Card, Image } from 'react-bootstrap'
import why from './why.svg'

export const Why: React.FC<{}> = (props) => {
  return (
    <>
      <Row className="justify-content-center align-items-center mb-4">
        <Col xs="auto">
          <Image src={why} height="54px"></Image>
        </Col>
        <Col xs="auto">
          <h3>Why Choose HonestRX</h3>
        </Col>
      </Row>
      <Row xs={1} className="justify-content-center gap-3 mb-5">
        <Col md={3}>
          <Card className="shadow">
            <Card.Body>
              <Card.Title>Transparent Pricing</Card.Title>
              <Card.Text>
                Everything about Honest Rx is transparent! From our prices,
                location, to how we fill your order. Transparency is the core of
                our business model.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow">
            <Card.Body>
              <Card.Title>No Middle Men</Card.Title>
              <Card.Text>
                By not dealing with insurance companies, we aren’t controlled by
                them and neither are you. Want up to a year supply of a
                non-controlled medication—you got it! No rejections or
                authorizations from anyone other than your doctor and our
                pharmacist. Your health is your business, unlike virtually all
                other pharmacies, we don’t sell or share your information.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow">
            <Card.Body>
              <Card.Title>A Real Pharmacy</Card.Title>
              <Card.Text>
                Not a contracted prescription assembly line like most online
                pharmacies. Honest Rx is a community pharmacy that is taking its
                business model online because we are fed up with healthcare and
                pharmacy as the greedy shadowy mess that it currently is. We are
                a community pharmacy so you always know who filled your
                prescription and can communicate directly with them if
                needed—other online pharmacies can’t guarantee that!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
