import { Row, Col, Image } from 'react-bootstrap'
import about from './about.svg'

export const About: React.FC<{}> = (props) => {
  return (
    <>
      <Row className="p-4 justify-content-center">
        <Col xs="auto" md="5">
          <Row className="justify-content-center align-items-center mb-4">
            <Col xs="auto">
              <Image src={about} height="54px"></Image>
            </Col>
            <Col xs="auto">
              <h3>About Us</h3>
            </Col>
          </Row>
          <Row>
            <p>
              An independent retail pharmacy driven by an obsession with getting
              the BS out of pharmacy and bringing trust and transparency back.
              Generic medications much cheaper than most pharmacies and many
              co-pays. Insurance NOT accepted.
            </p>
          </Row>
        </Col>
      </Row>
    </>
  )
}
