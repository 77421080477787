import { ListGroup } from 'react-bootstrap'

interface Props {
  drug: Drug
}

export const DrugListItem: React.FC<Props> = ({ drug }: Props) => {
  const cost = drug.controlled
    ? drug.tabletCost * drug.unitCount * 1.5 + 8.0
    : drug.tabletCost * drug.unitCount * 1.2 + 6.5

  return (
    <ListGroup.Item>
      <p>
        <strong className="fs-5 text-primary">{drug.name}</strong>
        <br />
        <span className="fs-6 text-secondary">
          Generic for {drug.brandName}
        </span>
        <br />
        {drug.strength} | {drug.unitCount}&nbsp;{drug.units}
        <br />
        <strong className="text-primary">${cost.toFixed(2)}</strong>
      </p>
    </ListGroup.Item>
  )
}
