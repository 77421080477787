import { useState } from 'react'
import { Row, Col, Button, Form, ListGroup } from 'react-bootstrap'
import drugs from './drugs.json'
import { DrugListItem } from './DrugListItem'

export const Search: React.FC<{}> = (props) => {
  const [query, setQuery] = useState('')
  const [activeQuery, setActiveQuery] = useState('')

  const drugResults =
    activeQuery.length > 0
      ? drugs
          .filter(
            (drug) =>
              drug.name.toLowerCase().includes(activeQuery.toLowerCase()) ||
              drug.brandName.toLowerCase().includes(activeQuery.toLowerCase())
          )
          .slice(0, 10)
      : []

  const textDidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.currentTarget.value

    setQuery(newQuery)

    if (newQuery.length == 0 && drugResults.length == 0) {
      setActiveQuery('')
    }
  }

  const didKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setActiveQuery(query)
    }
  }

  const didSubmitSearch = (event: React.SyntheticEvent) => {
    setActiveQuery(query)
  }

  return (
    <Row className="p-4 secondary-background justify-content-center mb-5">
      <Col>
        <Row className="justify-content-center mb-md-3 mb-2" xs="auto">
          <h2>Get honest prices for prescription drugs</h2>
        </Row>
        <Row className="justify-content-center mb-md-4 mb-3" xs="auto">
          We source high-quality generic drugs and pass on the savings to you.
        </Row>
        <Row className="justify-content-center" xs="auto">
          <Col sm="12" md="8" xs="12" className="mb-3 mb-md-0">
            <Form.Control
              type="text"
              name="searchValue"
              onChange={textDidChange}
              onKeyDown={didKeyDown}
              placeholder="Search the entire store here..."></Form.Control>
          </Col>
          <Col xs="auto">
            <Button type="submit" onClick={didSubmitSearch}>
              <strong>Find your medication</strong>
            </Button>
          </Col>
        </Row>
        {drugResults.length > 0 && (
          <Row className="mt-3 justify-content-center">
            <Col xs={12} lg={5}>
              <ListGroup>
                {drugResults.map((drug) => (
                  <DrugListItem
                    drug={drug}
                    key={drug.brandName + drug.strength + drug.unitCount}
                  />
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}
        {drugResults.length == 0 && activeQuery.length > 0 && query.length > 0 && (
          <Row className="mt-3 justify-content-center">
            <Col xs="auto">
              <Row xs="auto" className="justify-content-center">
                <h3 className="primary-color">No Results</h3>
              </Row>
              <Row xs="auto" className="justify-content-center">
                <p>
                  Call us at <a href="tel:540-490-4350">540-490-4350</a> or send
                  us a message on{' '}
                  <a href="https://www.facebook.com/Honest-Rx-110400501643325">
                    Facebook
                  </a>
                </p>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}
